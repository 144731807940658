<template>
  <table class="dg-cpe-table">
    <tr class="dg-cpe-table-header">
      <th>Audit Process</th>
      <th>Question group</th>
      <th>{{ name }}</th>
    </tr>
    <tr class="dg-cpe-table-areas">
      <td colspan="2"></td>
      <td v-for="(entity, index) in entities" v-bind:key="index">{{ entity.name }}</td>
    </tr>
    <tr class="dg-cpe-table-processes" v-for="auditProcess in filteredEntityMapping" v-bind:key="auditProcess.id">
      <td class="dg-cpe-table-process-title">{{ auditProcess.name }}</td>
      <td v-if="auditProcess" class="dg-cpe-table-process-qg">
        <tr
          :class="['dg-cpe-table-qg-name', questionGroup.name.length > 30 ? 'abbreviated' : '']"
          v-for="(questionGroup, questionGroupIdTr) in questionGroupsForAuditProcess(auditProcess)"
          v-bind:key="questionGroupIdTr"
        >
          <span>{{ questionGroup.name }}</span>
        </tr>
      </td>

      <td v-for="entity in entities" v-bind:key="entity.id">
        <tr v-for="questionGroup in questionGroupsForAuditProcess(auditProcess)" v-bind:key="questionGroup.id">
          <span class="toggle">
            <DgToggle
              :disabled="!toggleActive(questionGroup, entity, auditProcess)"
              :checked="isToggleChecked(questionGroup, entity)"
              @change="onToggle($event, auditProcess, questionGroup, entity)"
            />
          </span>
        </tr>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import { Basic, Structural } from "@/dgui-customer-components/";
export default {
  name: "DgMappingTable",
  components: {
    DgToggle: Basic.DgToggle,
  },
  props: {
    entities: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    entityKeyName: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      mappingDataExchanges: "getDataExchangesForEntity",
    }),
    filteredEntityMapping() {
      return this.dataExchanges.filter(e => e.question_groups.length > 0);
    },
    getEntityDecoded() {
      return this.entityKeyName.includes("internal_") ? this.entityKeyName.split("_")[1] : this.entityKeyName;
    },
    dataExchanges() {
      return this.mappingDataExchanges(this.getEntityDecoded);
    },
  },
  methods: {
    questionGroupsForAuditProcess(auditProcess) {
      return (this.dataExchanges.find(e => e.id === auditProcess.id) || {}).question_groups;
    },
    isToggleChecked(questionGroup, entity) {
      const mappingList =
        this.entityKeyName === "data_subjects"
          ? questionGroup.data_exchanges.map(d => d.data_subject)
          : questionGroup[this.entityKeyName];
      return (
        mappingList && mappingList.find(e => e.id === entity.id && e.entity_type === entity.entity_type) !== undefined
      );
    },
    toggleActive(questionGroup, entity, auditProcess) {
      if (this.entityKeyName === "data_subjects") {
        return true;
      } else {
        let mappingList = this.mappingDataExchanges("data_subjects")
          .map(qs => qs.question_groups)
          .flat();
        let result = mappingList.find(el => el.id === questionGroup.id);
        return result ? result.data_exchanges.length > 0 : false;
      }
    },

    onToggle(checked, auditProcess, questionGroup, entity) {
      this.$store.dispatch("addStateToToogle", { data: true });
      this.$store.dispatch("toggleEntity", {
        checked,
        auditProcessId: auditProcess.id,
        questionGroupId: questionGroup.id,
        entity,
        entityKeyName: this.entityKeyName,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-cpe-table {
  width: 100%;
  background-color: #ffffff;
  display: inline-block;
  overflow: auto;

  .dg-cpe-table-header > th {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    padding: 16px 0;
  }

  .dg-cpe-table-areas > td {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    text-align: center;
    border-right: 1px solid #c9d2d7;
    word-break: break-word;
  }

  .dg-cpe-table-processes {
    border-bottom: 1px solid #c9d2d7;
    .dg-cpe-table-process-title {
      display: flex;
      font-size: 16px;
      font-weight: bold;
      align-items: center;
      width: 200px;
      word-break: break-word;
    }

    .dg-cpe-table-process-qg {
      .dg-cpe-table-qg-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
        display: block;
        &.abbreviated {
          span {
            &:hover {
              height: auto;
              display: flex;
              flex-wrap: wrap;
              overflow: hidden;
              white-space: pre-wrap;
              background-color: white;
              box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
              border-radius: 2px;
              width: 200px;
              z-index: 5;
            }
          }
        }
      }
    }
    & > td > tr {
      height: 56px;
      line-height: 20px;
      display: flex;
      align-items: center;
      width: 200px;
    }

    & > td:not(:first-child) {
      border-right: 1px solid #c9d2d7;
      padding: 10px;
      font-size: 12px;
    }
    .toggle {
      margin-top: -15px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
