<template>
  <div>
    <div class="dg-redirect" v-if="!getSelectedOrgData(entityKey, variant)">
      <h3>No {{ variant }} Entities available</h3>
      <p>Add data to start with the mapping.</p>
      <DgButton @click="setActiveTab" type="secondary">
        <img class="plus-icon" src="../../assets/icons/plus-24.svg" />
        Add Data
      </DgButton>
    </div>
    <div v-else>
      <EntityMappingTable
        :name="getLabel"
        :entities="getSelectedOrgData(entityKey, variant)"
        :entityKeyName="variant === 'internal' ? internalMappingEntityKeyName : entityKey"
        :entityType="variant"
      />
      <DgPagination
        v-if="totalPages && totalPages !== 1"
        :totalPages="totalPages"
        :disableRouting="true"
        @pageNumberChanged="fetchDataExchanges"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Basic } from "@/dgui-customer-components/";
import EntityMappingTable from "../EntityMappingTable";
export default {
  name: "Internal-Mapping",
  props: {
    variant: {
      type: String,
    },
    entityKey: {
      type: String,
    },
    totalPages: {
      type: Number,
    },
  },
  components: {
    DgButton: Basic.DgButton,
    DgPagination: Basic.DgPagination,
    EntityMappingTable,
  },
  computed: {
    ...mapGetters({
      getSelectedOrgData: "selectedOrgData",
      mappingDataExchanges: "getDataExchangesForEntity",
    }),
    internalMappingEntityKeyName() {
      return this.entityKey !== "data_subjects" ? `internal_${this.entityKey}` : this.entityKey;
    },
    getLabel() {
      return `${this.capitalizeFirstLetter(this.variant)} ${this.capitalizeFirstLetter(this.entityKey)}`;
    },
    dataExchanges() {
      return this.mappingDataExchanges(this.entityKey);
    },
  },
  methods: {
    setActiveTab() {
      this.$emit("activeTabClicked");
    },
    fetchDataExchanges(pageNumber) {
      this.$emit("fetchDataExchanges", pageNumber);
    },
  },
};
</script>
