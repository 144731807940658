<template>
  <div>
    <div v-if="variant === 'category'">
      <h4 class="dg-cpe__section-title primary">Internal</h4>
      <div
        class="dg-category"
        v-for="(entity, index) in getSelectedOrgData(entityKey, 'internal')"
        v-bind:key="'int_' + index"
      >
        <h3 class="dg-category__section-title">{{ entity.name }}</h3>
        <DgMultiselect
          v-if="setMultiselectValues(mappingData.newDataCategoryTypes)"
          :list="setMultiselectValues(mappingData.newDataCategoryTypes)"
          :selectedValues="getSelectedFiltersForDataCategories(entity)"
          label="Filter"
        />
        <DgMultiselect
          v-if="setMultiselectValues(mappingData.newDataCategories)"
          :list="setMultiselectValues(mappingData.newDataCategories)"
          label="Data"
          :selectedValues="getDataCategoriesForSubject(entity)"
          @selection="addDataCategoryToSubject($event, entity)"
        />
        <!-- <MultiSelectGroup
          :groupName="entity.name"
          :primaryList="setMultiselectValues(mappingData.newDataCategoryTypes)"
          :selectedPrimaryList="getSelectedFiltersForDataCategories(entity)"
          :secondaryList="setMultiselectValues(mappingData.newDataCategories)"
          :selectedSecondaryList="getDataCategoriesForSubject(entity)"
          @selection="addDataCategoryToSubject($event, entity)"
        />-->
      </div>
      <h4 class="dg-cpe__section-title secondary">External</h4>
      <div
        class="dg-category"
        v-for="(entity, index) in getSelectedOrgData(entityKey, 'external')"
        v-bind:key="'ext_' + index"
      >
        <h3 class="dg-category__section-title">{{ entity.name }}</h3>
        <DgMultiselect
          v-if="setMultiselectValues(mappingData.newDataCategoryTypes)"
          :list="setMultiselectValues(mappingData.newDataCategoryTypes)"
          :selectedValues="getSelectedFiltersForDataCategories(entity)"
          label="Filter"
          variant="secondary"
        />
        <DgMultiselect
          v-if="setMultiselectValues(mappingData.newDataCategories)"
          :list="setMultiselectValues(mappingData.newDataCategories)"
          :selectedValues="getDataCategoriesForSubject(entity)"
          label="Data"
          variant="secondary"
          @selection="addDataCategoryToSubject($event, entity)"
        />
      </div>
    </div>
    <div v-else-if="variant === 'data'">
      <h4 class="dg-cpe__section-title primary">Internal</h4>
      <DgMultiselect
        v-if="setMultiselectValues(mappingData.organisationPartTypes)"
        :list="setMultiselectValues(mappingData.organisationPartTypes)"
        :selectedValues="getSelectedFilters(getSelectedOrgData(entityKey, 'internal'))"
        @selection="organisationPartsSelection"
        label="Filter"
      />
      <DgMultiselect
        v-if="setMultiselectValues(organisationParts)"
        :list="setMultiselectValues(organisationParts)"
        :selectedValues="setMultiselectValues(getSelectedOrgData(entityKey, 'internal'))"
        :label="internalLabel"
        @selection="addSelectedEntity($event, 'internal')"
      />
      <h4 class="dg-cpe__section-title secondary">External</h4>
      <DgMultiselect
        v-if="setMultiselectValues(mappingData.organisationTypes)"
        :list="setMultiselectValues(mappingData.organisationTypes)"
        :selectedValues="getSelectedFilters(getSelectedOrgData(entityKey, 'external'))"
        @selection="organisationsSelection"
        label="Filter"
        variant="secondary"
      />
      <DgMultiselect
        v-if="setMultiselectValues(organisations)"
        :list="setMultiselectValues(organisations)"
        :selectedValues="setMultiselectValues(getSelectedOrgData(entityKey, 'external'))"
        :label="externalLabel"
        variant="secondary"
        @selection="addSelectedEntity($event, 'external')"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Form } from "@/dgui-customer-components/";
// import MultiSelectGroup from "./MultiSelectGroup";
export default {
  name: "Category-Tab",
  components: {
    DgMultiselect: Form.DgMultiselect,
    // MultiSelectGroup,
  },
  props: {
    variant: {
      type: String,
    },
    entityKey: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      mappingData: "getMappingData",
      getSelectedOrgData: "selectedOrgData",
      getOrganisationData: "organisationData",
    }),
    internalMappingEntityKeyName() {
      return this.entityKey !== "data_subjects" ? `internal_${this.entityKey}` : this.entityKey;
    },
    internalLabel() {
      return `Internal ${this.capitalizeFirstLetter(this.entityKey)}`;
    },
    externalLabel() {
      return `External ${this.capitalizeFirstLetter(this.entityKey)}`;
    },
    organisationParts() {
      return this.getOrganisationData(this.entityKey, "parts");
    },
    organisations() {
      return this.getOrganisationData(this.entityKey);
    },
  },
  methods: {
    getSelectedData(tabType, entityType, selection) {
      let tab, filterBy, filteredList;
      entityType === "internal" ? (filterBy = "external") : (filterBy = "internal");
      tabType === "senders"
        ? (tab = "selectedSenders")
        : tabType === "receivers"
        ? (tab = "selectedReceivers")
        : tabType === "data_subjects"
        ? (tab = "selectedDataSubjects")
        : "";
      filteredList = this.mappingData[tab].filter(val => val.entity_type === filterBy);
      return filteredList.concat(selection);
    },
    async getDataWithFilters(resource, filterIds) {
      let responseData;
      const path = `/api1/v2/data_exchanges/${resource}`;
      const url = filterIds ? `${path}?${filterIds.map(id => `by_filter_type[]=${id}`).join("&")}` : path;
      const response = await this.$http.get(url);
      return response.data.data;
    },
    addSelectedEntity(data, entityType) {
      this.$store.dispatch("addStateToToogle", { data: true });
      let entitiesMasterList = [];
      let orignalEntitiesList = [];
      let actionName = "";
      let entityKeyName = "";

      entitiesMasterList = entityType === "internal" ? this.organisationParts : this.organisations;
      orignalEntitiesList = this.getSelectedOrgData(this.entityKey, entityType);
      entityKeyName = entityType === "internal" ? this.internalMappingEntityKeyName : this.entityKey;

      const entitiesFromData = entitiesMasterList.filter(e => data.includes(e.name));
      const removedEntities = orignalEntitiesList.filter(e => !data.includes(e.name));

      if (this.entityKey === "data_subjects") {
        entitiesFromData
          .filter(e => orignalEntitiesList.filter(o => o.id === e.id).length)
          .forEach(e => {
            e.data_categories = orignalEntitiesList.find(o => o.id === e.id).data_categories;
          });
      }

      this.$store.dispatch("setSelectedEntities", {
        data: this.getSelectedData(this.entityKey, entityType, entitiesFromData),
        entityKeyName: this.entityKey,
      });

      if (removedEntities.length) {
        this.$store.dispatch("removeSelectedEntities", {
          selectedEntities: entitiesFromData,
          entityKeyName:
            entityKeyName.includes("data_") && entityKeyName !== "data_subjects"
              ? entityKeyName.split("data_")[1]
              : entityKeyName,
          removedEntities,
        });
      }
    },
    async organisationPartsSelection(organisationPartsNames) {
      this.$store.dispatch("addStateToToogle", { data: true });
      if (organisationPartsNames.length) {
        let filterIds;
        filterIds = organisationPartsNames.map(organisationPartname => {
          return this.getOrganisationData(organisationPartname, "part_types").id;
        });
        const organisationParts = await this.getDataWithFilters("organisation_parts", filterIds);
        this.$store.dispatch("setMappingData", {
          keyName: "organisation_parts",
          data: organisationParts,
          entityKeyName: this.entityKey,
        });
      } else {
        this.$store.dispatch("setMappingData", {
          keyName: "organisation_parts",
          data: await this.getDataWithFilters("organisation_parts"),
          entityKeyName: this.entityKey,
        });
      }
    },
    async organisationsSelection(organisationsNames) {
      this.$store.dispatch("addStateToToogle", { data: true });
      if (organisationsNames.length) {
        let filterIds;
        filterIds = organisationsNames.map(organisationName => {
          return this.getOrganisationData(organisationName, "types").id;
        });
        const organisations = await this.getDataWithFilters("organisations", filterIds);
        this.$store.dispatch("setMappingData", {
          keyName: "organisations",
          data: organisations,
          entityKeyName: this.entityKey,
        });
      } else {
        this.$store.dispatch("setMappingData", {
          keyName: "organisations",
          data: await this.getDataWithFilters("organisations"),
          entityKeyName: this.entityKey,
        });
      }
    },
    getSelectedFilters(data) {
      return Array.from(new Set(data.map(value => value.filter_type)));
    },
    addDataCategoryToSubject(data, subject) {
      this.$store.dispatch("addDataCategoryToSubject", {
        subject,
        data,
      });
    },
    getDataCategoriesForSubject(subject) {
      return subject && subject.data_categories
        ? Array.from(new Set(subject.data_categories.map(value => value.name)))
        : [];
    },
    getSelectedFiltersForDataCategories(subject) {
      return subject && subject.data_categories
        ? Array.from(new Set(subject.data_categories.map(value => value.filter_type)))
        : [];
    },
    setMultiselectValues(data) {
      if (Array.isArray(data)) {
        let values;
        values =
          data &&
          data.map(value => {
            return value.name;
          });
        return values;
      }
      return;
    },
  },
};
</script>
