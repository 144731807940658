<template>
  <Accordion :variant="entityKeyName" @displayState="accordionDisplay">
    <div v-if="this.getOrganisationData(this.entityKeyName).length">
      <div class="dg-cpe__section-content">
        <DgTabs ref="sendersTabs">
          <DgTab name="Data" :selected="true">
            <CategoryTab variant="data" :entityKey="entityKeyName" />
          </DgTab>
          <DgTab name="Categories" v-if="categoryTabApplicable">
            <CategoryTab variant="category" :entityKey="entityKeyName" />
          </DgTab>
          <DgTab :name="internalMappingLabel">
            <MappingTab
              :totalPages="totalPages"
              @fetchDataExchanges="fetchDataExchanges"
              @activeTabClicked="setActiveTab"
              :entityKey="entityKeyName"
              variant="internal"
            />
          </DgTab>
          <DgTab :name="externalMappingLabel">
            <MappingTab
              :totalPages="totalPages"
              @fetchDataExchanges="fetchDataExchanges"
              @activeTabClicked="setActiveTab"
              :entityKey="entityKeyName"
              variant="external"
            />
          </DgTab>
        </DgTabs>
      </div>
    </div>
    <div v-else>Loading...</div>
  </Accordion>
</template>

<script>
import { mapGetters } from "vuex";
import { Structural, Basic, Form } from "@/dgui-customer-components/";

// Local Components
import Accordion from "./Accordion";
import EntityMappingTable from "./EntityMappingTable";
import MappingTab from "./TabContent/MappingTab";
import CategoryTab from "./TabContent/CategoryTab";

export default {
  name: "EntitiesPartial",
  components: {
    DgTab: Structural.DgTab,
    DgTabs: Structural.DgTabs,
    Accordion,
    MappingTab,
    CategoryTab,
  },
  props: {
    entityKeyName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entityLabel: this.capitalizeFirstLetter(this.entityKeyName),
      totalPages: null,
      perPage: 15,
    };
  },
  mounted() {
    this.getInitialData();
  },
  computed: {
    ...mapGetters({
      mappingData: "getMappingData",
      mappingDataExchanges: "getDataExchangesForEntity",
      getOrganisationData: "organisationData",
      getSelectedOrgData: "selectedOrgData",
      getLoading: "loading",
    }),
    categoryTabApplicable() {
      return this.entityKeyName === "data_subjects";
    },
    internalMappingLabel() {
      return `Mapping Internal ${this.entityLabel}`;
    },
    externalMappingLabel() {
      return `Mapping External ${this.entityLabel}`;
    },
    includeParam() {
      return this.entityKeyName == "data_subjects"
        ? ["question_groups.data_exchanges.data_subject"]
        : [`question_groups.${this.entityKeyName}`, `question_groups.internal_${this.entityKeyName}`];
    },
  },
  methods: {
    async getInitialData() {
      await this.$http
        .get(`/api1/v2/industries/${this.$route.params.id}/selected_entities?by_entity=${this.entityKeyName}`)
        .then(response => {
          this.$store.dispatch("setSelectedEntities", {
            data: response.data.data,
            entityKeyName: this.entityKeyName,
          });
        });
      this.fetchDataExchanges(1);
      if (this.categoryTabApplicable) {
        this.getData("new_data_category_types", "SET_MAPPING_NEW_DATA_CATEGORY_TYPES");
        this.getData("new_data_categories", "SET_MAPPING_NEW_DATA_CATEGORIES");
      }
    },
    accordionDisplay(displayState) {
      // console.log("accordion:", this.entityKeyName, displayState);
    },
    getData(resource, keyName) {
      this.$http.get(`/api1/v2/data_exchanges/${resource}`).then(response => {
        this.$store.dispatch("setMappingData", {
          keyName,
          data: response.data.data,
        });
      });
    },
    setActiveTab() {
      const tabs = this.$refs.sendersTabs;
      const firstTab = this.$refs.sendersTabs.$children[0];
      tabs.selectTab(firstTab);
    },
    fetchDataExchanges(pageNumber) {
      this.$http
        .get(
          `/api1/v2/industries/${this.$route.params.id}/data_exchanges?${this.includeParam
            .map(resource => `include[]=${resource}`)
            .join("&")}&page=${pageNumber}&per=${this.perPage}`
        )
        .then(response => {
          if (!this.totalPages) {
            this.totalPages = response.data.total_pages;
          }

          let result = this.mappingData.dataExchangesToSave
            .filter(res => {
              return response.data.data.find(el => {
                return el.id === res.id;
              });
            })
            .map(el => {
              let question_group = el.question_groups.map(qs => {
                let qs_group = qs;
                if (this.entityKeyName === "data_subjects") {
                  qs_group.data_exchanges = qs.data_exchanges;
                }
                qs_group["internal_" + this.entityKeyName] =
                  "internal_" + this.entityKeyName in qs && qs["internal_" + this.entityKeyName].length
                    ? qs["internal_" + this.entityKeyName]
                    : [];
                qs_group[this.entityKeyName] =
                  this.entityKeyName in qs && qs[this.entityKeyName].length ? qs[this.entityKeyName] : [];

                return qs_group;
              });
              el.question_groups = question_group;
              return el;
            });

          this.$store.dispatch("setDataExchangesForEntities", {
            data: result.length ? result : response.data.data,
            entityKeyName: this.entityKeyName,
          });
        });
    },
  },
};
</script>
