<template>
  <div class="cpe-page-header">
    <DgBreadcrumb @buttonClick="backToIndustryPage" />
    <div class="cpe-page-header-industry">Industry: {{ industry }}</div>
    <div class="cpe-page-header-subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
import "./CpePageHeader.scss";
import { Basic } from "@/dgui-customer-components/";
import { mapGetters } from "vuex";

export default {
  name: "dg-cpe-page-header",
  components: {
    DgBreadcrumb: Basic.DgBreadcrumb,
  },
  computed: {
    ...mapGetters({
      gettoogleState: "getChangedState",
    }),
  },
  methods: {
    backToIndustryPage() {
      if (this.gettoogleState) {
        this.$dgToast.show({
          type: "confirm",
          title: "Data will not be saved",
          content: "Selected Data Exchanges will be lost, are you sure you want to go back to industry selection?",
          confirmButtonLabel: "Yes, go back",
          rejectButtonLabel: "Cancel",
          onAccept: () => {
            this.$router.go(-1);
          },
        });
      } else {
        this.$router.go(-1);
      }
    },
    addStateToToogle() {
      this.$store.dispatch("addStateToToogle", { data: !this.gettoogleState });
    },
  },
  props: {
    industry: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
  },
};
</script>
