<template>
  <div>
    <div class="dg-cpe-accordion">
      <div class="dg-cpe-accordion-header" @click.prevent="toggleAccordion()">
        <span class="dg-cpe-accordion-title" v-if="variant === 'senders'">
          <img class="dg-cpe-accordion-image" src="../assets/icons/senders_icon.svg" />Senders</span
        >
        <span class="dg-cpe-accordion-title" v-if="variant === 'receivers'"
          ><img class="dg-cpe-accordion-image" src="../assets/icons/receivers_icon.svg" />Receivers</span
        >
        <span class="dg-cpe-accordion-title" v-if="variant === 'data_subjects'"
          ><img class="dg-cpe-accordion-image" src="../assets/icons/data_subjects_icon.svg" />Data Subjects</span
        >
        <i class="dgi dgi-small-down" :class="{ rotate: active === true }" />
      </div>
      <div class="dg-cpe-accordion-content" v-show="active">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.active = !this.active;
      this.$emit("displayState", this.active);
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-cpe-accordion {
  margin: 24px 0;

  .dg-cpe-accordion-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #7e93a7;
    padding: 8px;

    &:hover {
      cursor: pointer;
    }

    .dg-cpe-accordion-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: #003349;

      .dg-cpe-accordion-image {
        margin-right: 16px;
      }
    }

    i {
      font-size: 32px;

      &.rotate {
        transform: rotate(180deg);
        transition-duration: 0.35s;
      }
    }
  }
}
</style>
