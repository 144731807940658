<template>
  <div>
    <div class="container-fluid">
      <div class="row justify-content-md-center">
        <div class="col-10">
          <CpePageHeader :industry="mappingData.industryName" subtitle="Data Exchanges" />
        </div>
      </div>
      <div v-if="!isLoading">
        <div class="row justify-content-md-center">
          <div class="col-10">
            <Entity v-for="(entity, i) in entityList" :entityKeyName="entity" :key="i" />
          </div>
        </div>
      </div>
      <div v-else class="loading">
        <img src="../assets/icons/ajax-loader.gif" />Please wait while we update the Data...
      </div>
    </div>
    <DgActionBar>
      <DgButton href @click="saveData" :disabled="!saveEnabled">Save changes</DgButton>
    </DgActionBar>
  </div>
</template>
<script>
import { Structural, Basic } from "@/dgui-customer-components/";
import CpePageHeader from "../components/CpePageHeader/CpePageHeader";
import { mapGetters } from "vuex";
import Entity from "../components/Entity";
export default {
  name: "CPE_Home",
  data() {
    return {
      isLoading: false,
      entityList: ["data_subjects", "senders", "receivers"],
    };
  },
  components: {
    DgActionBar: Structural.DgActionBar,
    DgButton: Basic.DgButton,
    CpePageHeader,
    Entity,
  },
  computed: {
    ...mapGetters({
      mappingData: "getMappingData",
    }),
    saveEnabled() {
      return (
        this.mappingData.dataExchangesToSave.filter(d => {
          if (d.question_groups && d.question_groups.length) {
            return (
              "question_groups" in d &&
              d.question_groups.filter(
                q => "data_exchanges" in q && q.data_exchanges.filter(de => de.data_subject).length > 0
              ).length > 0
            );
          }
        }).length > 0
      );
    },
  },
  methods: {
    saveData() {
      this.isLoading = true;
      let submitData = {
        audit_processes: this.mappingData.dataExchangesToSave.filter(a => a.question_groups.length > 0),
      };
      this.$http
        .post(`/api1/v2/industries/${this.$route.params.id}/data_exchanges`, submitData)
        .then(response => {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(error => {
          this.isLoading = false;
          this.$dgToast.show({
            type: "info",
            title: error,
            content: "Error submiting data, please try again.",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.54);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
}

.loading img {
  margin-right: 6px;
}
</style>
